import './index.less';

import vsf, { definePage, renderRoutes } from '@vs/vsf-boot';
import {
  FloatingBubble,
  Loading,
  Mask,
  SpinLoading,
  TabBar,
  TabBarItem,
  Toast,
} from '@vs/vsf-mobile';
import React, { useEffect, useRef, useState } from 'react';
import VConsole from 'vconsole';

import frameClose from '@/assets/frameClose.png';
import houseKeepImg from '@/assets/framehousekeep.png';
import { text_url } from '@/config/url_list';
import { RouteWithTab } from '@/router';
import { getEnv, getENVIR, getUrlConfig } from '@/utils';
import storage from '@/utils/storage';
type ContainerProps = {
  route: RouteWithTab;
  location: Location;
  routes: any;
};

const patientTabRouteList = ['/index', '/order_list', '/housekeep'];
const patientHasPlanTabRouteList = [
  '/index',
  '/daily_quest',
  '/order_list',
  '/housekeep',
  '/mine',
];
const doctorTabRouteList = [
  '/health_care_order_list',
  '/case_management',
  '/health_care_appraise',
];

const patientRouterList = [
  '/index',
  '/daily_quest',
  '/order_list',
  '/housekeep',
  '/mine',
  '/specialist_serrvice_detail',
  '/service_list',
  '/serviceDetails',
  '/face_to_face_consultation_request_form',
  '/summer_html',
];

const doctorRouterList = [
  '/health_care_order_list',
  '/case_management',
  '/health_care_appraise',
  '/interview',
  '/cases_detail',
];

const floatingBubbleList = [
  {
    path: '/index',
    type: 'DEFAULT',
  },
  {
    path: '/serviceDetails',
    type: 'DETAILS',
  },
  {
    path: '/specialist_serrvice_detail',
    type: 'ORDER',
  },
];

const Container: any = (props) => {
  // console.log('=====params=====', props.routes?.query);

  const { token, userAccount } = props.routes?.query || {};
  const { route, routes } = props;
  const { user, plan, version } = props.stores;
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState<any>(
    route.routes?.filter((item) => !!item.tab),
  );
  const [floatingBubbleIsShow, setFloatingBubbleIsShow] = useState<any>(null);
  const planNumRef = useRef<any>(0);

  const {
    doctorUserInfo,
    patientUserInfo,
    currentUserInfo,
    setDoctorUserInfo,
    setPatientUserInfo,
    setCurrentUserInfo,
  } = user;

  const { planData, setPlanData } = plan;
  const { versionInfo, setVersionInfo, versionIsUp, setVersionIsUp } = version;
  const { pathname } = props.location;
  const tabs = route.routes?.filter((item) => !!item.tab);

  if (getEnv() !== 'prod') {
    const vConsole = new VConsole();
  }

  useEffect(() => {
    if (routes) {
      const findRoutObj = floatingBubbleList?.find(
        (v) => v.path == routes?.pathname,
      );
      setFloatingBubbleIsShow(
        findRoutObj ? { ...routes, ...findRoutObj } : undefined,
      );
    }
  }, [routes]);

  const handleChangeHost = () => {
    const value = {
      token: currentUserInfo?.token,
      userAccount: currentUserInfo?.userAccount,
      ...(props.routes?.query || {}),
    };
    const searchStr = new URLSearchParams(value).toString();
    console.log(searchStr);
    if (location.origin === text_url.self) {
      location.href = text_url.new_self + location.pathname + '?' + searchStr;
    } else {
      location.href = text_url.self + location.pathname + '?' + searchStr;
    }
  };

  const initPlanData = () => {
    setTimeout(() => {
      setPlanData();
      if (planNumRef.current < 5) {
        initPlanData();
      }
      planNumRef.current = planNumRef.current + 1;
    }, 150);
  };

  const checkVersion = () => {
    if (versionIsUp) {
      setVersionIsUp(false);
      vsf.showToast('当前版本已更新');
    }
    fetch('/version.json')
      .then((response) => response.json())
      .then((data) => {
        console.log('当前版本号: ', data?.version, versionInfo);
        if (versionInfo !== data?.version) {
          setVersionInfo(data?.version);
          setVersionIsUp(true);
          location.reload();
        }
      });
  };

  useEffect(() => {
    if (token && userAccount) {
      if (patientRouterList.find((item) => item === pathname)) {
        handleLogin({ token, userAccount });
        initPlanData();
      } else if (doctorRouterList.find((item) => item === pathname)) {
        handleTabList(doctorTabRouteList);
        handleAppLogin({ token, userAccount });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userAccount, pathname]);

  useEffect(() => {
    if (patientUserInfo?.token) {
      setPlanData();
    }
    checkVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, patientUserInfo]);

  useEffect(() => {
    if (patientUserInfo?.token) {
      if (planData) {
        handleTabList(patientHasPlanTabRouteList);
      } else {
        handleTabList(patientTabRouteList);
      }
    }
    if (doctorUserInfo?.token) {
      handleTabList(doctorTabRouteList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData, patientUserInfo, doctorUserInfo]);

  const handleTabList = (list) => {
    const tabList = list
      ?.map((v) => {
        const findObj = route.routes?.find((item) => item?.path == v);
        return findObj;
      })
      ?.filter((v) => v);
    setTab(tabList);
  };

  const handleLogin = async (value) => {
    const loginValue = {
      ...value,
      token: decodeURIComponent(value?.token),
    };
    const res = await vsf?.services?.OrderRecordController_patientLogin_977c7e({
      param: loginValue,
    });
    if (res?.code === 200) {
      if (!res?.data) {
        Toast.show({
          icon: 'fail',
          content: '系统异常',
        });
        window?.uni.navigateBack({
          delta: 1,
        });
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 120);
        setPatientUserInfo(value);
        setDoctorUserInfo(null);
        setCurrentUserInfo(value);
      }
    }
  };

  const handleAppLogin = async (value) => {
    const loginValue = {
      ...value,
      token: decodeURIComponent(value?.token),
    };
    const res = await vsf?.services?.OrderRecordController_doctorLogin_0d6faa({
      param: loginValue,
    });
    if (res?.code === 200) {
      if (!res?.data) {
        Toast.show({
          icon: 'fail',
          content: '系统异常',
        });
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 250);
        setDoctorUserInfo(value);
        setPatientUserInfo(null);
        setCurrentUserInfo(value);
      }
    }
  };
  const jsonToBase64 = (jsonString) => {
    // 将JSON字符串转换为UTF-8编码的字节序列
    const utf8Bytes = unescape(encodeURIComponent(jsonString))
      .split('')
      .map(function (c) {
        return c.charCodeAt(0);
      });

    // 将字节序列转换为Base64字符串
    const base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));

    return base64String;
  };
  const handleFloatingBubble = async () => {
    const url = location?.href;
    let data: any = {};
    if (floatingBubbleIsShow?.type == 'ORDER') {
      const res =
        await vsf.services.OrderRecordController_getPatientOrderDetailById_0cc644(
          {
            id: floatingBubbleIsShow?.query?.orderId,
          },
        );
      if (res.code === 200) {
        const value = res?.data;
        data = {
          visitorProduct: {
            title: `订单编号：${value?.orderNumber}<br/>订单名称：${value?.bundleName}<br/>患者姓名：${value?.visitPatient?.patientName}`,
            url: url,
          },
          name: value?.visitPatient?.patientName,
        };
      }
    } else if (floatingBubbleIsShow?.type == 'DETAILS') {
      const res =
        await vsf?.services?.ServeBundleController_getServeBundleById_a3d7d9({
          id: floatingBubbleIsShow?.query?.id,
        });
      if (res.code === 200) {
        const value = res?.data;
        data = {
          visitorProduct: {
            title: value?.bundleName,
            // price: '￥9.9',
            img: value?.bundleImg,
            url: url,
          },
        };
      }
    } else {
      data = {};
    }
    // const url = `${location?.origin}${location?.search}`;
    const jsonStr = JSON.stringify(data); // 将JSON对象转换为字符串
    window.location.href =
      location.href = `https://aichat.byteawake.com/chatIndex?ent_id=3&visitor_id=${
        currentUserInfo?.userAccount
      }&visitor_name=${data?.name ?? '访客用户'}&extra=${jsonToBase64(jsonStr)}`;
  };
  if (loading && token && userAccount) {
    return (
      <Mask visible={true}>
        <SpinLoading className="login_loading" />
      </Mask>
    );
  }

  const renderSafeArea = () => {
    if (getENVIR()?.includes('wx')) {
      const operatingSystem = navigator.userAgent;
      const isiOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const isAndroid = navigator.userAgent.match(/Android/i);
      console.log(
        operatingSystem,
        `ios:${isiOS}`,
        `Android:${isAndroid}`,
        'operatingSystem',
      );
      return isiOS ? '22px' : '0px';
    }
  };
  // console.log(tab, route, props?.routes, props?.location, '111111');

  return (
    <div className="vsf-layout">
      {/* {floatingBubbleIsShow && (
        <FloatingBubble
          axis="xy"
          magnetic="x"
          className="FloatingBubble"
          onClick={handleFloatingBubble}
        >
          <div className="Bubble-content">
            <img src={houseKeepImg} className="FloatingBubble-img"></img>
          </div>
        </FloatingBubble>
      )} */}

      <div className="vsf-layout-main">
        {renderRoutes(route.routes, route.redirect)}
      </div>
      {getEnv() !== 'prod' ? (
        <div className="change_host" onClick={handleChangeHost}>
          {location.origin === text_url.self ? '切院内环境' : '切本地环境'}
        </div>
      ) : null}
      {tabs?.find((tab) => tab.path === pathname) && (
        <div
          className="vsf-layout-tabbar"
          style={{ paddingBottom: renderSafeArea() }}
        >
          <TabBar
            activeKey={pathname}
            onChange={(value) => vsf.history?.replace(value)}
          >
            {tab?.map((item) => (
              <TabBarItem
                key={item.path}
                icon={item.tab?.icon}
                title={item.tab?.title}
              />
            ))}
          </TabBar>
        </div>
      )}
    </div>
  );
};

export default definePage(Container);
