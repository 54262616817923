import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, NavBar, SearchBar, Toast } from '@vs/vsf-mobile';
import { useThrottleFn } from 'ahooks';
import { FileOutline, RightOutline } from 'antd-mobile-icons';
import React, { useEffect, useRef, useState } from 'react';

import Dom_title from '@/components/dom_title';
import Empty from '@/components/Empty';
const size = 20;
const HealthEducation: React.FC = (props: any) => {
  const { routes } = props;
  const [list, setList] = useState<any[]>([]);
  const [urlstate, setUrlState] = useUrlState(routes.query);
  const [searchValue, setSearchValue] = useState<string>(
    urlstate?.searchValue ?? '',
  );

  useEffect(() => {
    handleHealthEducationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleVideDtails = async (value) => {
    console.log(value, '----');
    // detailId
    vsf?.navigateTo(
      `/taskDetail?detailId=${value?.id}&type=list&isExecute=false&taskCategory=1`,
    );
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleHealthEducationList = async () => {
    const res =
      await vsf?.services?.PlanPatientController_queryByPlanTaskAndTaskMasterQto_548c7c(
        {
          qto: {
            planMasterIdIs: routes.query?.id,
            taskMasterUsedDetailTitleLike: searchValue,
          },
        },
      );
    if (res?.code == 200) {
      setList(res?.data);
    }
  };

  const { run } = useThrottleFn(handleSearchChange, { wait: 1000 });

  const handleClickVideo = (value) => {
    window.uni?.navigateTo({
      url: `/pages/consult/health-course/course-detail/course-detail?id=${value?.id}`,
    });
  };

  const renderVideoItem = (data) => {
    const { taskMaster } = data;
    const { usedDetail } = taskMaster;
    return (
      <div
        className="video-item"
        onClick={() => {
          setUrlState({ searchValue });
          handleVideDtails(usedDetail);
        }}
      >
        <div className="video-header">
          <img className="img" src={usedDetail?.iconImg}></img>
        </div>
        <div className="video-content">
          <div className="header-title">{usedDetail?.title}</div>
          <div className="header-bottom">{usedDetail?.taskIntroduction}</div>
        </div>
        <RightOutline color="#505F75" fontSize={12} />
      </div>
    );
  };

  return (
    <>
      <NavBar children="健康宣教" backArrow />
      <Dom_title title="健康宣教" />
      <div className="HealthEducation-container">
        <SearchBar
          placeholder="搜索"
          onChange={run}
          className="search"
          defaultValue={searchValue}
        />
        <div className="HealthEducation-content">
          {list?.map((v) => renderVideoItem(v))}

          {list?.length == 0 && <Empty text="暂无健康宣教" />}
        </div>
      </div>
    </>
  );
};

export default definePage(HealthEducation);
