import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, ImageUploader, NavBar } from '@vs/vsf-mobile';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import empty from '@/assets/empty1.png';
import DomTitle from '@/components/dom_title';
import { isImgSrc } from '@/utils';

const size = 50;

interface IndicatorItemType {
  value?: any;
  key?: number;
  type?: string;
  onJumpUrl?: (e) => void;
}

const IndicatorItem: React.FC<IndicatorItemType> = (props) => {
  const { value, onJumpUrl, type } = props;
  return (
    <div className="IndicatorItem">
      <div className="item-title">{value?.planPathway?.title}路径</div>
      <div className="item-content">
        <div className="title">预警规则</div>
        <div className="text">{value?.warnRuleDescription}</div>
      </div>
      <div className="item-content">
        <div className="title">处理意见</div>
        <div
          className={classNames(`text`, {
            url: value?.serveBundleId && type !== 'health_care',
          })}
          onClick={() => {
            value?.serveBundleId &&
              type !== 'health_care' &&
              onJumpUrl &&
              onJumpUrl(value);
          }}
        >
          {value?.warnSuggest}
        </div>
      </div>
    </div>
  );
};

const IndicatorDetails: React.FC<any> = (props) => {
  const { id, planPatientId, type, planId } = props.routes.query;
  const [headerList, setHeaderList] = useState<any>([{}, {}, {}, {}, {}]);
  const [currentHeader, setCurrentHeader] = useState<any>();
  const [data, setData] = useState<any>();
  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    handleGetMineInfo();
    if (planId) getUnExecuteTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetMineInfo = async () => {
    const res =
      await vsf?.services?.RagPlanController_getAllByQueryPlanMasterIndicatorFrontQto_e9348e(
        {
          qto: {
            size,
            from: 0,
            planPatientIdIn: [planPatientId],
            onlineStatusIs: true,
          },
        },
      );
    if (res?.code === 200) {
      const data = res?.data;
      const indicatorsList = data
        ?.map((v) => v?.planTaskExecuteWithElementDtoList)
        ?.flat()
        ?.map((v) => v?.planTaskExecuteElement)
        ?.flat()
        ?.sort((a, b) => b?.isAbnormal - a?.isAbnormal);
      if (!planId) setHeaderList(indicatorsList);
      setData(data);
      if (id && !planId) {
        const filterList =
          indicatorsList?.filter((v) => v?.elementId != id) ?? [];
        const findObj = indicatorsList?.find((v) => v?.elementId == id);
        setHeaderList([findObj, ...filterList]);
      }
      if (id)
        return setCurrentHeader(
          indicatorsList?.find((v) => v?.elementId == id),
        );
      setCurrentHeader(indicatorsList?.[0]);
    }
  };

  // 查询个案详情--待处理
  const getUnExecuteTasks = async () => {
    const res =
      await vsf.services?.PlanDoctorController_getUnExecuteTasks_9d5953({
        planId,
      });
    if (res.code === 200) {
      const indicatorsList = res.data.planTaskExecuteElementVoList ?? [];
      if (id) {
        const filterList =
          indicatorsList?.filter((v) => v?.elementId != id) ?? [];
        const findObj = indicatorsList?.find((v) => v?.elementId == id);
        setHeaderList([findObj, ...filterList]);
      } else {
        setHeaderList(indicatorsList);
      }
    }
  };

  const handleJump = (value) => {
    const id = value?.serveBundleId;
    vsf?.navigateTo(`/service_details?id=${id}`);
  };

  const renderStr = (value) => {
    if (value?.length > 10) {
      return `${value?.substring(0, 10)}...`;
    }
    return value;
  };

  const renderIndicatorInfo = (value, type = 'info') => {
    if (type == 'info') {
      return data
        ?.map((v) => v?.planIndicatorMonitoringBaseDtoList)
        ?.flat()
        ?.filter((v) => v?.projectKey == currentHeader?.elementId)
        ?.filter((v) => (planId ? v?.planMasterId == planId : true));
    } else {
      return data
        ?.map((v) => v?.planIndicatorMonitoringBaseDtoList)
        ?.flat()
        ?.filter((v) => v?.projectKey == currentHeader?.elementId)
        ?.map((v) => v?.planTaskExecuteElement)
        ?.flat();
    }
  };

  const renderEmpty = () => {
    return (
      <div className="render-empty">
        <img src={empty} className="render-img"></img>
        <div className="render-empty-text">暂无数据</div>
      </div>
    );
  };
  return (
    <>
      <NavBar children="指标记录" backArrow={false} />
      <DomTitle title="指标记录" />
      <div className="IndicatorDetails-container">
        <div className="IndicatorDetails-header">
          {headerList?.map((v, index) => (
            <div
              className={classNames(`header-item`, {
                activeItem: currentHeader?.elementId == v?.elementId,
                sign: v?.isAbnormal,
              })}
              key={index}
              onClick={() => setCurrentHeader(v)}
            >
              {renderStr(v?.projectName)}
            </div>
          ))}
        </div>
        <div className="IndicatorDetails-content">
          {renderIndicatorInfo(data)?.length > 0 &&
          currentHeader?.isAbnormal ? (
            <div className="indicator-opinion">
              <div className="indicator-header">
                {currentHeader?.projectName}指标异常处理意见
              </div>
              {renderIndicatorInfo(data)?.map((v, index) => (
                <IndicatorItem
                  value={v}
                  key={index}
                  onJumpUrl={handleJump}
                  type={type}
                ></IndicatorItem>
              ))}
            </div>
          ) : (
            ''
          )}
          {renderIndicatorInfo(data, 'tab')?.length > 0 ? (
            <div className="record">
              <div className="record-header">指标记录</div>
              <div className="record-content">
                <div className="item item-header">
                  <div className="time">测量时间</div>
                  <div className="time">数值</div>
                </div>
                {renderIndicatorInfo(data, 'tab')?.map((v, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className="time">
                        {dayjs(v?.createdAt)?.format('YYYY-MM-DD')}
                      </div>

                      <div className="time">
                        {isImgSrc(v?.elementValue) ? (
                          <ImageUploader
                            value={[{ url: v?.elementValue }]}
                            maxCount={1}
                            deletable={false}
                            showUpload={false}
                            disableUpload
                            upload={() => {
                              return new Promise((resolveOuter) => {
                                resolveOuter(new Promise((resolveInner) => {}));
                              });
                            }}
                          ></ImageUploader>
                        ) : (
                          // <img
                          //   className="table-img"
                          //   src={v?.elementValue}
                          // ></img>
                          <>
                            {v?.elementValue}
                            {v?.normalValueUnit ?? ''}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ''
          )}
          {renderIndicatorInfo(data)?.length == 0 &&
            renderIndicatorInfo(data, 'tab')?.length == 0 &&
            renderEmpty()}
        </div>
      </div>
    </>
  );
};

export default definePage(IndicatorDetails);
