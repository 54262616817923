// import Index from '@/pages';
import Agreement from '@pages/agreement';
import AppraiseDetails from '@pages/appraise_details/index';
import FaceToFaceConsultationRequestForm from '@pages/face_to_face_consultation_request_form';
import HealthCareAppraise from '@pages/health_care_appraise';
import HealthCareOrderDetail from '@pages/health_care_order_detail';
import HealthCareOrderList from '@pages/health_care_order_list';
import HealthCareSerrviceDetail from '@pages/health_care_service_details';
import Index from '@pages/index/index';
import Interview from '@pages/interview';
import InterviewAgreement from '@pages/interview_agreenment';
import Invoice from '@pages/invoice';
import InvoiceSuccess from '@pages/invoice_success';
import OrderConfirmation from '@pages/order_confirmation/index';
import OrderDetail from '@pages/order_detail';
import OrderList from '@pages/order_list/index';
import OrderPay from '@pages/order_pay/index';
import ServiceDetails from '@pages/service_details/index';
import Servicelist from '@pages/service_list/index';
import SpecialistSerrviceDetail from '@pages/specialist_serrvice_detail';
import summerHtml from '@pages/summerHtml';
import TaskDetail from '@pages/taskDetail';
import { RouteConfig } from '@vs/vsf-boot';
import { AppOutline, UserOutline } from '@vs/vsf-mobile-icons';
import React from 'react';

import activeCaseTab from '@/assets/activeCase.png';
import activeFrame from '@/assets/activeFrame.png';
import activeMineTab from '@/assets/activeMine.png';
import caseTab from '@/assets/case.png';
import Frame from '@/assets/Frame.png';
import home1 from '@/assets/home1.png';
import home2 from '@/assets/home2.png';
import mineTab from '@/assets/mine.png';
import Slice51 from '@/assets/Slice51.png';
import Slicetab from '@/assets/Slicetab.png';
import tab1 from '@/assets/tab1.png';
import tab2 from '@/assets/tab2.png';
import tab3 from '@/assets/tab3.png';
import tab4 from '@/assets/tab4.png';
import tab5 from '@/assets/tab5.png';
import tab6 from '@/assets/tab6.png';
import Root from '@/layouts';
import CaseManagement from '@/pages/case_management';
import CasesDetail from '@/pages/cases_detail';
import DailyQuest from '@/pages/daily_quest';
import Guidance from '@/pages/guidance';
import HealthEducation from '@/pages/health_education';
import Housekeep from '@/pages/housekeep';
import IndicatorDetails from '@/pages/indicator_details';
import IntelligentButler from '@/pages/intelligent_butler';
import MedicalHistory from '@/pages/medicalHistory';
import Mine from '@/pages/mine/index';
import NewIndex from '@/pages/new_index';
import PatientInformation from '@/pages/patient_information';
import PatientList from '@/pages/patient_list';
import PrescriptionRecord from '@/pages/prescriptionRecord';
import reportQuery from '@/pages/reportQuery';
import VideoList from '@/pages/video_list';

import pageRoutes from './routes';

export type RouteWithTab = Omit<RouteConfig, 'path' | 'routes'> & {
  // tab属性自动识别为tab页
  tab?: {
    icon?: React.ReactNode;
    title?: React.ReactNode;
  };
  routes?: RouteWithTab[] | undefined;
  path: string;
};

const routes: RouteWithTab[] = [
  {
    path: '/',
    component: Root,
    redirect: '/index',
    routes: [
      // {
      //   path: '/index',
      //   exact: true,
      //   component: Index,
      //   // tab: {
      //   //   icon: <AppOutline />,
      //   //   title: '首页',
      //   // },
      // },
      {
        path: '/index',
        exact: true,
        component: NewIndex,
        // tab: {
        //   icon: <AppOutline />,
        //   title: '首页',
        // },
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={home1} className="tabBarIcon"></img>
            ) : (
              <img src={home2} className="tabBarIcon"></img>
            ),
          title: '首页',
        },
      },
      {
        path: '/daily_quest',
        exact: true,
        component: DailyQuest,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={Slicetab} className="tabBarIcon"></img>
            ) : (
              <img src={Slice51} className="tabBarIcon"></img>
            ),
          title: '每日任务',
        },
      },
      {
        path: '/housekeep',
        exact: true,
        component: Housekeep,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={activeFrame} className="tabBarIcon"></img>
            ) : (
              <img src={Frame} className="tabBarIcon"></img>
            ),
          title: '联系管家',
        },
      },
      {
        path: '/service_list',
        exact: true,
        component: Servicelist,
      },
      {
        path: '/service_details',
        exact: true,
        component: ServiceDetails,
      },
      {
        path: '/appraise_details',
        exact: true,
        component: AppraiseDetails,
      },
      {
        path: '/order_confirmation',
        exact: true,
        component: OrderConfirmation,
      },
      {
        path: '/order_list',
        exact: true,
        component: OrderList,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={tab4} className="tabBarIcon"></img>
            ) : (
              <img src={tab1} className="tabBarIcon"></img>
            ),
          title: '服务记录',
        },
      },
      {
        path: '/health_care_order_list',
        exact: true,
        component: CaseManagement,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={activeCaseTab} className="tabBarIcon"></img>
            ) : (
              <img src={caseTab} className="tabBarIcon"></img>
            ),
          title: '个案管理',
        },
      },
      {
        path: '/case_management',
        exact: true,
        component: HealthCareOrderList,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={tab4} className="tabBarIcon"></img>
            ) : (
              <img src={tab1} className="tabBarIcon"></img>
            ),
          title: '订单记录',
        },
      },
      {
        path: '/health_care_appraise',
        exact: true,
        component: HealthCareAppraise,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={tab6} className="tabBarIcon"></img>
            ) : (
              <img src={tab3} className="tabBarIcon"></img>
            ),
          title: '我的评价',
        },
      },
      {
        path: '/order_pay',
        exact: true,
        component: OrderPay,
      },
      {
        path: '/taskDetail',
        exact: true,
        component: TaskDetail,
      },
      {
        path: '/summer_html',
        exact: true,
        component: summerHtml,
      },
      {
        path: '/order_detail',
        exact: true,
        component: OrderDetail,
      },
      {
        path: '/health_care_order_detail',
        exact: true,
        component: HealthCareOrderDetail,
      },
      {
        path: '/face_to_face_consultation_request_form',
        exact: true,
        component: FaceToFaceConsultationRequestForm,
      },
      {
        path: '/specialist_serrvice_detail',
        exact: true,
        component: SpecialistSerrviceDetail,
      },
      {
        path: '/health_care_service_details',
        exact: true,
        component: HealthCareSerrviceDetail,
      },
      {
        path: '/agreement',
        exact: true,
        component: Agreement,
      },
      {
        path: '/video_list',
        exact: true,
        component: VideoList,
      },
      {
        path: '/interview',
        exact: true,
        component: Interview,
      },
      {
        path: '/interview_agreenment',
        exact: true,
        component: InterviewAgreement,
      },
      {
        path: '/invoice',
        exact: true,
        component: Invoice,
      },
      {
        path: '/invoice_success',
        exact: true,
        component: InvoiceSuccess,
      },
      {
        path: '/indicator_details',
        exact: true,
        component: IndicatorDetails,
      },
      // 就诊史
      {
        path: '/medical_history',
        exact: true,
        component: MedicalHistory,
      },
      // 检查检验
      {
        path: '/report_query',
        exact: true,
        component: reportQuery,
      },
      // 处方记录
      {
        path: '/prescription_record',
        exact: true,
        component: PrescriptionRecord,
      },
      {
        path: '/cases_detail',
        exact: true,
        component: CasesDetail,
      },
      {
        path: '/mine',
        exact: true,
        component: Mine,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={activeMineTab} className="tabBarIcon"></img>
            ) : (
              <img src={mineTab} className="tabBarIcon"></img>
            ),
          title: '我的',
        },
      },
      {
        path: '/patient_list',
        exact: true,
        component: PatientList,
      },
      // 全息视图
      {
        path: '/patient_information',
        exact: true,
        component: PatientInformation,
      },
      {
        path: '/intelligent_butler',
        exact: true,
        component: IntelligentButler,
      },
      {
        path: '/health_education',
        exact: true,
        component: HealthEducation,
      },
      {
        path: '/guidance',
        exact: true,
        component: Guidance,
      },
      ...pageRoutes,
    ],
  },
];

export default routes;
