import { createServices } from '@vs/vsf-boot';
export default createServices({
  RegTempDetailController_queryTaskById_9d30b6: {
    method: 'post',
    url: '/api/knowledge-base-temp/query-task-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: TaskDetailMasterSpecialistVo765641) =>
      data,
  },
  SpecialistGuidanceController_queryAllUnread_f73ea7: {
    method: 'post',
    url: '/api/knowledge-base-temp/query-all-unread',
    parameterFomatter: (data?: { planMasterIdIs: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  SpecialistGuidanceController_queryOrderServerItem_54d019: {
    method: 'post',
    url: '/api/knowledge-base-temp/query-order-server-item',
    parameterFomatter: (data?: { planMasterIdIs: number }) => data,
    responseFormatter: (_, __, data?: OrderServeItemDto37Bb49[]) => data,
  },
  TaskExecuteController_createTaskExecute_27abfc: {
    method: 'post',
    url: '/api/rag-plan/create-task-execute',
    parameterFomatter: (data?: {
      btoParam: CreateTaskExecuteBtoB624B4;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PlanTaskExecuteBaseVoDe76C9) => data,
  },
  TaskExecuteController_createTaskExecuteAndDetail_eb1f72: {
    method: 'post',
    url: '/api/rag-plan/create-task-execute-and-detail',
    parameterFomatter: (data?: {
      btoParam: CreateTaskExecuteBtoB624B4;
      doctorFlag: boolean;
    }) => data,
    responseFormatter: (_, __, data?: PlanTaskExecuteBaseVoDe76C9) => data,
  },
  PlanController_getPlanTaskDaily_14b191: {
    method: 'post',
    url: '/api/rag-plan/get-plan-task-daily',
    parameterFomatter: (data?: { qto: PlanStageExecuteDailyQto08B695 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PlanStageExecuteDailyVoDe76C9[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TaskExecuteController_getPlanTaskExecuteVoById_f11366: {
    method: 'post',
    url: '/api/rag-plan/get-plan-task-execute-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PlanTaskExecuteBaseVoDe76C9) => data,
  },
  TaskExecuteController_queryAbnormalIndicator_3fea70: {
    method: 'post',
    url: '/api/rag-plan/query-abnormal-indicator',
    parameterFomatter: (data?: { planTaskExecuteId: number }) => data,
    responseFormatter: (_, __, data?: AbnormalIndicatorVoDe76C9) => data,
  },
  TaskExecuteController_getPlanTaskExecuteDetailVoById_a7c2ff: {
    method: 'post',
    url: '/api/rag-plan/get-plan-task-execute-detail-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PlanTaskExecuteDetailVoDe76C9) => data,
  },
  PlanPatientController_queryByPlanTaskAndTaskMasterQto_548c7c: {
    method: 'post',
    url: '/api/rag-plan/query-by-plan-task-and-task-master-qto',
    parameterFomatter: (data?: { qto: PlanTaskAndTaskMasterQto08B695 }) => data,
    responseFormatter: (_, __, data?: PlanTaskAndTaskMasterVoDe76C9[]) => data,
  },
  PlanPatientController_getHistoryTaskDaily_849e29: {
    method: 'post',
    url: '/api/rag-plan/get-history-task-daily',
    parameterFomatter: (data?: {
      qto: PlanTaskDailyDetailQto08B695;
      page: number;
      size: number;
    }) => data,
    responseFormatter: (_, __, data?: PlanTaskStageAndExecuteVoDe76C9[]) =>
      data,
  },
  OssPolicyController_queryValueByCode_1b8f6d: {
    method: 'post',
    url: '/api/external-oss/query-value-by-code',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (_, __, data?: ResultVoCa3088) => data,
  },
  OssPolicyController_queryValueForObject_7ebe0b: {
    method: 'post',
    url: '/api/external-oss/query-value-for-object',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (_, __, data?: SelectListVoCa3088) => data,
  },
  ServeBundleContrastController_getListServeBundleSpecialistContrastVoByServeBundleId_ae8766:
    {
      method: 'post',
      url: '/api/serve-bundle/get-list-serve-bundle-specialist-contrast-vo-by-serve-bundle-id',
      parameterFomatter: (data?: { serveBundleId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ServeBundleSpecialistContrastVoF1Ba03[],
      ) => data,
    },
  SpecialistGuidanceController_queryBySpecialistGuidanceQto_b27134: {
    method: 'post',
    url: '/api/rag-temp/query-by-specialist-guidance-qto',
    parameterFomatter: (data?: { qto: SpecialistGuidanceQto5B7C86 }) => data,
    responseFormatter: (_, __, data?: SpecialistGuidanceVo765641[]) => data,
  },
});
