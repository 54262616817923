import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { Box, ImageUploader, Mask, NavBar } from '@vs/vsf-mobile';
import { RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import activeMineTab from '@/assets/activeMine.png';
import empty from '@/assets/empty1.png';
import step3_img from '@/assets/step/step3_img.png';
import step3_text from '@/assets/step/step3_text.png';
import DomTitle from '@/components/dom_title';
import { GenderEnum, PLANSTATUSENUM } from '@/config';
import { getENVIR, isImgSrc } from '@/utils';

const size = 50;

const Mine: React.FC<any> = (props) => {
  const [headerList, setHeaderList] = useState<any>([]);
  const [currentHeader, setCurrentHeader] = useState<any>();
  const [info, setInfo] = useState<any>([]);

  const [urlstate, setUrlState] = useUrlState(props?.routes?.query);
  const { plan } = props.stores;

  const { setStep, step } = plan;
  useEffect(() => {
    // handleGetMineInfo();
    handleInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentHeader?.id) {
      handleGetMineInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeader?.id]);

  const handleInfo = async () => {
    const res = await vsf?.services?.RagPlanController_getPatients_3a45e8({});
    if (res?.code == 200) {
      const data = res?.data;
      setHeaderList(res?.data);
      if (urlstate?.planPatientId) {
        setCurrentHeader(
          res?.data?.find((v) => v?.id == urlstate?.planPatientId),
        );
        const dom = document?.getElementById(urlstate?.planPatientId);
        dom && dom?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        const value = data?.filter((v) => v?.status == 'EXECUTE');
        setCurrentHeader(value?.[0] ?? data?.[0]);
      }
    }
  };

  const handleGetMineInfo = async () => {
    const res =
      await vsf?.services?.RagPlanController_getAllByQueryPlanMasterIndicatorFrontQto_e9348e(
        {
          qto: {
            size,
            from: 0,
            planPatientIdIn: [currentHeader?.id],
          },
        },
      );
    if (res?.code === 200) {
      const data = res?.data;
      setInfo(data);
      // const value = data?.filter((v) => v?.status == 'EXECUTE');
      // setCurrentHeader(value?.[0] ?? data?.[0]);
      // if (urlstate?.planPatientId) {
      //   setCurrentHeader(data?.find((v) => v?.id == urlstate?.planPatientId));
      //   const dom = document?.getElementById(urlstate?.planPatientId);
      //   dom && dom?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // }
    }
  };

  const handleJumpDetails = (value?: any) => {
    setUrlState({ planPatientId: currentHeader?.id });
    if (value)
      return vsf?.navigateTo(
        `/indicator_details?id=${value?.elementId}&planPatientId=${currentHeader?.id}`,
      );
    vsf?.navigateTo(`/indicator_details?planPatientId=${currentHeader?.id}`);
  };

  const handelStepEnd = () => {
    setStep(null);
  };
  const renderindicatorsList = (value, type = 'abnormol') => {
    const indicatorsAllList = info
      ?.map((v) => v?.planTaskExecuteWithElementDtoList)
      ?.flat()
      ?.map((v) => v?.planTaskExecuteElement)
      ?.flat();
    if (type == 'abnormol')
      return indicatorsAllList?.filter((v) => v?.isAbnormal);
    return indicatorsAllList?.filter((v) => !v?.isAbnormal);
  };

  const renderStr = (value) => {
    if (value?.length > 6) {
      return `${value?.substring(0, 6)}...`;
    }
    return value;
  };

  const renderEmpty = () => {
    return (
      <div className="render-mine-empty">
        <img src={empty} className="render-img"></img>
        <div className="render-empty-text">暂无指标记录</div>
      </div>
    );
  };

  const renderIsImg = (value) => {
    return isImgSrc(value?.elementValue) ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ImageUploader
          value={[{ url: value?.elementValue }]}
          maxCount={1}
          deletable={false}
          showUpload={false}
          disableUpload
          upload={() => {
            return new Promise((resolveOuter) => {
              resolveOuter(new Promise((resolveInner) => {}));
            });
          }}
        ></ImageUploader>
      </div>
    ) : (
      `${value?.elementValue}${value?.normalValueUnit ?? ''}`
    );
  };

  const renderText = (value) => {
    return typeof value?.isAbnormal == 'boolean' &&
      !isImgSrc(value?.elementValue)
      ? '正常'
      : '';
  };

  const renderIndicator = () => {
    if (
      renderindicatorsList(currentHeader)?.length > 0 ||
      renderindicatorsList(currentHeader, 'normal')?.length > 0
    ) {
      return (
        <div className="indicators">
          <div className="indicators-header">
            <div className="header-title">我的指标</div>
            <div
              className="header-rigth"
              onClick={() => {
                handleJumpDetails();
              }}
            >
              <span className="text">指标记录</span>
              <RightOutline fontSize={12} color="#A7AFBA" className="icon" />
            </div>
          </div>
          <div className="indicators-content">
            <div className="indicators-abnormol mine-col">
              {renderindicatorsList(currentHeader)?.length > 0 ? (
                <>
                  <div className="indicators-title mine-col">异常项</div>
                  <div className="abnormol-content">
                    {renderindicatorsList(currentHeader)?.map((v, index) => {
                      return (
                        <div
                          className="abnormol-item"
                          key={index}
                          onClick={() => {
                            handleJumpDetails(v);
                          }}
                        >
                          <div className="item-title">
                            {renderStr(v?.projectName)}
                          </div>
                          <div className="item-num">{renderIsImg(v)}</div>
                          <div className="abnormol-state">异常</div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="indicators-abnormol mine-col">
              {renderindicatorsList(currentHeader, 'normal')?.length > 0 ? (
                <>
                  <div className="indicators-title mine-col">标准项</div>
                  <div className="abnormol-content normol">
                    {renderindicatorsList(currentHeader, 'normal')?.map(
                      (v, index) => {
                        return (
                          <div
                            className="abnormol-item"
                            key={index}
                            onClick={() => {
                              handleJumpDetails(v);
                            }}
                          >
                            <div className="item-title">
                              {renderStr(v?.projectName)}
                            </div>
                            <div className="item-num">{renderIsImg(v)}</div>
                            <div className="abnormol-state normol-text">
                              {renderText(v)}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return renderEmpty();
    }
  };
  const renderSafeArea = () => {
    if (getENVIR()?.includes('wx')) {
      const operatingSystem = navigator.userAgent;
      const isiOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const isAndroid = navigator.userAgent.match(/Android/i);
      console.log(
        operatingSystem,
        `ios:${isiOS}`,
        `Android:${isAndroid}`,
        'operatingSystem',
      );
      return isiOS ? '22px' : '0px';
    }
  };

  const headerJumpDetails = (value?: any) => {
    if (value) {
      return vsf?.navigateTo(
        `/medical_history?id=${value?.elementId}&planPatientId=${currentHeader?.id}`,
      );
    }
    vsf?.navigateTo(`/medical_history?planPatientId=${currentHeader?.id}`);
  };

  return (
    <>
      <NavBar children="我的" backArrow={false} />
      <DomTitle title="我的" />
      <div className="mine-container">
        <div className="mine-header mine-col">
          {headerList?.map((v, index) => (
            <div
              className={classNames(`header-item`, {
                headerItemActive: currentHeader?.id == v?.id,
              })}
              key={index}
              id={v?.id}
              onClick={() => setCurrentHeader(v)}
            >
              {v?.patientName}
            </div>
          ))}
        </div>
        <div className="mine-content">
          <div className="mine-info mine-col">
            <div className="info-name">{currentHeader?.patientName}</div>
            <div className="info-contnet">
              <div className="info-col">
                {GenderEnum?.[currentHeader?.patientGender]}
              </div>
              <div className="info-col">{currentHeader?.age}岁</div>
              <div className="info-col col-num">{currentHeader?.phone}</div>
            </div>
          </div>
          {renderIndicator()}
        </div>
      </div>
      <Mask visible={step === 3}>
        <div className="step3">
          <img className="step3_img" src={step3_img} alt="" />
          <div className="step3_btn" onClick={handelStepEnd}>
            我知道了
          </div>
          <div className="box">
            <div className="box_con"></div>
            <div className="box_line">
              <img className="step3_text" src={step3_text} alt="" />
            </div>
          </div>
        </div>
        <div className="step3_tabBar_item" style={{ bottom: renderSafeArea() }}>
          <img src={activeMineTab} alt="" />
          <div className="tabBar_item_text">我的</div>
        </div>
      </Mask>
    </>
  );
};

export default definePage(Mine);
