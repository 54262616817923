import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { ImageUploader, NavBar, Tabs } from '@vs/vsf-mobile';
import { RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Slice45 from '@/assets/Slice45.png';
import Slice46 from '@/assets/Slice46.png';
import Slice47 from '@/assets/Slice47.png';
import Slice48 from '@/assets/Slice48.png';
import user_avatar from '@/assets/user_avatar.png';
import Dom_title from '@/components/dom_title';
import Empty from '@/components/Empty';
import { GenderEnum, taskExecuteENUM } from '@/config';
import { contentHtml, isImgSrc } from '@/utils';
const pngList = [
  {
    taskCategory: 4,
    name: '健康日记',
    defPng: Slice45,
    // activePng: Slice41,
    data: [],
  },
  {
    taskCategory: '2-3',
    name: '治疗任务',
    defPng: Slice48,
    // activePng: Slice44,
    data: [],
  },
  {
    taskCategory: 5,
    name: '随访问卷',
    defPng: Slice46,
    // activePng: Slice42,
    data: [],
  },
  {
    taskCategory: 6,
    name: '评估量表',
    defPng: Slice47,
    // activePng: Slice43,
    data: [],
  },
];
const CasesDetail: any = (props) => {
  const { planId, type, activeCard } = props.routes?.query || {};

  const [plan, setPlan] = useState<AccountPlanVoDe76C9 | any>();

  const [taskDaily, setTaskDaily] = useState<any>();
  const [taskDaily1, setTaskDaily1] = useState<any>([]);
  const [unExecuteTasks, setUnExecuteTasks] = useState<any>();
  const [urlstate, setUrlState] = useUrlState(
    {
      activeKey: type || 'WAIT_AUDIT',
      activeCard: undefined,
    },
    { navigateMode: 'replace' },
  );
  const initactiveCardRef = useRef(0);

  const handleClickBack = () => {
    vsf?.goBack();
  };
  const handleTabsChange = (value) => {
    // setActiveKey(value);
    setUrlState({
      activeKey: value,
    });
  };
  // 查询个案
  const getPlanById = async () => {
    const res = await vsf.services?.PlanDoctorController_getPlanById_5b90dc({
      planId,
    });
    if (res.code === 200) {
      setPlan(res.data ?? {});
    }
  };
  // 查询个案详情--待处理
  const getUnExecuteTasks = async () => {
    const res =
      await vsf.services?.PlanDoctorController_getUnExecuteTasks_9d5953({
        planId,
      });
    if (res.code === 200) {
      setUnExecuteTasks(res.data ?? {});
    }
  };
  // 查询个案详情
  const getTasksForDoctor = async () => {
    const res =
      await vsf.services?.PlanDoctorController_getTasksForDoctor_4e3be7({
        planId,
      });
    if (res.code === 200) {
      const data = pngList.map((item) => {
        const list = res.data.filter((f) => {
          if (item.taskCategory === '2-3') {
            return (
              f.taskMaster.taskCategory == 2 || f.taskMaster.taskCategory == 3
            );
          }
          return f.taskMaster.taskCategory == item.taskCategory;
        });
        return {
          ...item,
          data: list,
          executeNum: list?.filter((item) => item.isExecute).length,
        };
      });
      setTaskDaily({
        data,
        executeNum: res.data?.filter(
          (item) => !item.isExecute && item.taskMaster.taskCategory != 1,
        ).length,
        num: res.data?.filter((item) => item.taskMaster.taskCategory != 1)
          .length,
      });

      setTaskDaily1(
        res.data?.filter((item) => item.taskMaster.taskCategory == 1),
      );
      if (initactiveCardRef.current === 0 && activeCard) {
        initactiveCardRef.current = 1;
        return;
      }
      initactiveCardRef.current = 1;
      setUrlState({
        activeCard: data?.find((item) => item.data?.length)?.taskCategory,
      });
    }
  };

  useEffect(() => {
    if (!planId) return;
    if (!unExecuteTasks && urlstate?.activeKey === 'WAIT_AUDIT') {
      getUnExecuteTasks();
    }
    if (!taskDaily && urlstate?.activeKey === 'AUDIT_SUCCESS') {
      getTasksForDoctor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlstate?.activeKey, planId]);
  useEffect(() => {
    planId && getPlanById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  const renderText = (value) => {
    return typeof value?.isAbnormal == 'boolean' &&
      !isImgSrc(value?.elementValue)
      ? '正常'
      : '';
  };
  const handleJumpDetails = (value?: any) => {
    if (value)
      return vsf?.navigateTo(
        `/indicator_details?id=${value?.elementId}&planId=${planId}&planPatientId=${plan?.planPatient?.patient?.id}&type='health_care'`,
      );
    vsf?.navigateTo(
      `/indicator_details?planId=${planId}&planPatientId=${plan?.planPatient?.patient?.id}&type='health_care`,
    );
  };

  const renderList = () => {
    const { activeCard } = urlstate;
    const list = taskDaily?.data?.find(
      (item) => item.taskCategory == activeCard,
    );
    if (!list?.data?.length) {
      return null;
    }
    return (
      <div className="item_info">
        {list.data?.map((item) => (
          <div
            className={classNames('quest_item', {
              quest_item_active: item.isExecute,
            })}
            key={item.id + item.planDate}
            onClick={() => {
              vsf?.navigateTo(
                `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.planTaskExecute?.[0]?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
              );
            }}
          >
            <div className="title_box">
              <div className="text ellipsis1">{item.title}</div>
              <div className="btn">{item.isExecute ? '已完成' : '未完成'}</div>
            </div>
            <div className="time_box">
              <div className="plan_time">
                计划时间：
                {dayjs(item.planDate).format('YYYY/MM/DD')}
              </div>

              <div className="success_time">
                完成时间：
                {item.isExecute
                  ? dayjs(item.planTaskExecute?.[0]?.finishDateTime).format(
                      'YYYY/MM/DD',
                    )
                  : '-'}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const renderTaskCategory1List = () => {
    if (!taskDaily1.length) return null;
    return (
      <div className="d_card d_card1">
        <div className="title">健康宣教</div>
        <div className="heal_list">
          {taskDaily1?.map((item) => (
            <div
              className="heal_item"
              key={item.taskCategory}
              onClick={() => {
                vsf?.navigateTo(
                  `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.planTaskExecute?.[0]?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
                );
              }}
            >
              <div className="heal_title">
                <div className="text">{item.title}</div>
                <RightOutline />
              </div>
              <div className="heal_content">{contentHtml(item.content)}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderUserInfoCard = (
    <div className="user_info">
      <div className="avatar">
        <img src={user_avatar} alt="" />
      </div>
      <div className="user_name_box">
        <div className="user_name">
          {plan?.planPatient?.patient?.patientName ?? '-'}
        </div>
        <div className="user_gender">
          {plan?.planPatient?.patient?.patientGender
            ? GenderEnum[plan?.planPatient?.patient?.patientGender]
            : '-'}
        </div>
        <div className="user_age">{plan?.age ?? '-'}岁</div>
      </div>
      <div className="user_right">
        <div className="user_right_text ellipsis1">
          {plan?.currentPlanPathway?.title ?? '-'}
        </div>
      </div>
    </div>
  );
  const renderIsImg = (value) => {
    return isImgSrc(value?.elementValue) ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ImageUploader
          value={[{ url: value?.elementValue }]}
          maxCount={1}
          deletable={false}
          showUpload={false}
          disableUpload
          upload={() => {
            return new Promise((resolveOuter) => {
              resolveOuter(new Promise((resolveInner) => {}));
            });
          }}
        ></ImageUploader>
      </div>
    ) : (
      `${value?.elementValue}${value?.normalValueUnit ?? ''}`
    );
  };
  const renderindicatorsList = (value, type = 'abnormol') => {
    const indicatorsList = value?.planTaskExecuteElementVoList;
    if (type == 'abnormol') return indicatorsList?.filter((v) => v?.isAbnormal);
    return indicatorsList?.filter((v) => !v?.isAbnormal);
  };
  const renderStr = (value, len) => {
    if (value?.length > len) {
      return `${value?.substring(0, len)}...`;
    }
    return value;
  };
  const renderCard1 = () => {
    const list = unExecuteTasks?.planTaskVoList?.filter(
      (item) =>
        item.taskMaster?.taskCategory == 6 &&
        item.taskExecute?.status === 'WAIT_ADD',
    );
    if (!list?.length) return null;
    return (
      <div className="render_card">
        <div className="title">评估量表</div>
        <div className="list1">
          {list?.map((item) => (
            <div className="list1_item" key={item.key}>
              <div className="list1_item_name ellipsis1">{item.title}</div>
              <div
                className="list1_item_btn"
                onClick={() => {
                  vsf?.navigateTo(
                    `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.taskExecute?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
                  );
                }}
              >
                去补充
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderCard2 = () => {
    const list = unExecuteTasks?.planTaskVoList?.filter(
      (item) =>
        item.taskMaster?.taskCategory == 5 &&
        dayjs(item.planDate).isBefore(
          dayjs(dayjs().format('YYYY-MM-DD 00:00:00')),
        ),
    );
    if (!list?.length) return null;
    return (
      <div className="render_card">
        <div className="title">随访问卷</div>
        <div className="list1">
          {list?.map((item) => (
            <div className="list1_item" key={item.key}>
              <div className="list1_item_name ellipsis1">{item.title}</div>
              <div
                className="list1_item_btn"
                onClick={() => {
                  vsf?.navigateTo(
                    `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.taskExecute?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
                  );
                }}
              >
                去完成
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderIndicator = () => {
    if (
      !renderindicatorsList(unExecuteTasks)?.length &&
      !renderindicatorsList(unExecuteTasks, 'normal')?.length
    )
      return null;
    return (
      <div className="indicators">
        <div className="indicators-header">
          <div className="header-title">指标记录</div>
        </div>
        <div className="indicators-content">
          <div className="indicators-abnormol mine-col">
            {renderindicatorsList(unExecuteTasks)?.length > 0 ? (
              <>
                <div className="indicators-title mine-col">异常项</div>
                <div className="abnormol-content">
                  {renderindicatorsList(unExecuteTasks)?.map((v, index) => {
                    return (
                      <div
                        className="abnormol-item"
                        key={index}
                        onClick={() => {
                          handleJumpDetails(v);
                        }}
                      >
                        <div className="item-title">
                          {renderStr(v?.projectName, 6)}
                        </div>
                        <div className="item-num">{renderIsImg(v)}</div>
                        <div className="abnormol-state">异常</div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
          <div className="indicators-abnormol mine-col">
            {renderindicatorsList(unExecuteTasks, 'normal')?.length > 0 ? (
              <>
                <div className="indicators-title mine-col">标准项</div>
                <div className="abnormol-content normol">
                  {renderindicatorsList(unExecuteTasks, 'normal')?.map(
                    (v, index) => {
                      return (
                        <div
                          className="abnormol-item"
                          key={index}
                          onClick={() => {
                            handleJumpDetails(v);
                          }}
                        >
                          <div className="item-title">
                            {renderStr(v?.projectName, 6)}
                          </div>
                          <div className="item-num">{renderIsImg(v)}</div>
                          <div className="abnormol-state normol-text">
                            {renderText(v)}
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  const tabList = [
    {
      title: '待处理',
      value: 'WAIT_AUDIT',
      children: (
        <div className="tab_content1">
          {renderUserInfoCard}
          {renderCard1()}
          {renderCard2()}
          {renderIndicator()}
          {!renderCard1() && !renderCard2() && !renderIndicator() ? (
            <div className="empty_content">
              <Empty type="health_care" text="暂无待处理任务" />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: '任务详情',
      value: 'AUDIT_SUCCESS',
      children: (
        <div className="tab_content2">
          {renderUserInfoCard}
          {taskDaily?.data?.length &&
          taskDaily?.data?.find((item) => item.data.length) ? (
            <div className="d_card">
              <div className="title">任务列表</div>
              <div className="d_card_list">
                {taskDaily?.data?.map((item) => {
                  if (!item.data.length) return null;
                  return (
                    <div
                      className={classNames('d_card_item', {
                        active: item.taskCategory == urlstate?.activeCard,
                      })}
                      key={item.taskCategory}
                      onClick={() =>
                        setUrlState({ activeCard: item.taskCategory })
                      }
                    >
                      <img className="card_img" src={item.defPng} alt="" />
                      <div className="d_name">{item.name}</div>
                      <div className="d_num">
                        {item.executeNum ?? '-'}/{item.data?.length ?? '-'}
                      </div>
                    </div>
                  );
                })}
              </div>
              {renderList()}
            </div>
          ) : null}
          {/* {renderTaskCategory1List()} */}
          {!taskDaily?.data?.length ||
          !taskDaily?.data?.find((item) => item.data.length) ? (
            <div className="empty_content">
              <Empty type="health_care" text="暂无任务" />
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <>
      <NavBar children="个案详情" backArrow onBack={handleClickBack} />
      <Dom_title title="个案详情" />
      <div className="cases_detail">
        <Tabs
          className="tabs"
          activeKey={urlstate?.activeKey}
          onChange={handleTabsChange}
        >
          {tabList.map((item) => {
            return (
              <Tabs.Tab title={item.title} key={item.value}>
                {item.children}
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default definePage(CasesDetail);
